@import url('https://fonts.googleapis.com/css?family=Patua+One&display=swap');

#title {
  text-align: center;
  font-size: 2.9em;
  text-shadow: 2px 2px 2px #ffd000;
}

.wrapper {
  animation: scroll 70s linear infinite;
  background-image: url('../src/images/honeycomb.png');
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

@keyframes scroll {
  100%{
   background-position:0px -3000px;
 }
}

@media (prefers-reduced-motion) {
 .wrapper {
   animation: scroll 200s linear infinite;
 }
}

@media (min-width: 670px) {
 .title {
   font-size: 5rem;
 }
}

#main{
 font-family: 'Patua One';
 text-align: center;
 background: white;
 border-radius: 25px;
 padding: 2%;
 border: 3px solid black;
 min-width:85%;
 max-width: 85%;
 min-height:85%;
 max-height: 85%;
}

#photo{
  width: 90%;
  max-width: 450px;
  border-radius: 10%;
  border: 5px solid #ffd000;
}

.Buttons{
  color: black;
  background-color: #ffd000;
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 1.7em;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  height: fit-content;
}

.Buttons:hover{
  background-color: #ffa200;
  box-shadow: 10px 5px black;
  color: black;
  text-decoration: none;
}

.icons {
  max-width: 40px
}

#bee {
  width: 80px;
  position: absolute;
  transform: rotate(-30deg);
  left:10%;
  animation: wobble 2s ease infinite;
}
@keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}